<template>
  <v-row>
    <v-col v-for="(tagGroup, index) in tagGroups" :key="index" cols="12" md="3">
      <v-card :loading="loading">
        <v-card-title class="accent">
          <v-icon class="mr-2">mdi-account-question</v-icon>
          {{ tagGroup.descricao }}
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                text
                @click="getTagGroups"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>Recarregar</span>
          </v-tooltip>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6" class="d-flex align-center justify-center">
              <v-icon size="100px" color="xbColor">
                mdi-account-arrow-down
              </v-icon>
              <div class="font-weight-bold text-h2">
                {{ tagGroup.entraram }}
              </div>
            </v-col>

            <v-col cols="12" lg="6" class="d-flex align-center justify-center">
              <v-icon size="100px" color="error">mdi-account-arrow-up</v-icon>
              <div class="font-weight-bold text-h2">{{ tagGroup.sairam }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import api from "@/api/axios_service";
export default {
  name: "BoxTagGroups",

  data() {
    return {
      loading: false,
      tagGroups: [],
    };
  },

  methods: {
    async getTagGroups() {
      this.loading = true;

      const response = await api.get("/tags-groups");
      this.tagGroups = response.data.result;

      this.loading = false;
    },
  },

  created() {
    this.getTagGroups();
  },
};
</script>

<style></style>
